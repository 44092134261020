<template>
  <section class="table">
    <div class="table__header">
      <div
        class="table__col"
        :class="{ fixed: col.fixed }"
        :style="`flex-basis:${col.fixed}px`"
        v-for="(col, k) in cols"
        :key="`table-header-${k}`"
      >
        {{ col.label }}
      </div>
    </div>

    <div class="table__body" v-if="loading">
      <b-skeleton
        v-for="i in 10"
        :key="i"
        width="100%"
        height="150px"
        class="m-0 my-3"
      ></b-skeleton>
    </div>

    <div class="table__body" v-if="!loading && data && data.length === 0">
      <div
        class="d-flex justify-content-center align-items-center text-secondary mt-2"
        style="width: 100%; height: 400px;background:rgba(37, 48, 67, 0.2)"
      >
        没有物品哦~
      </div>
    </div>

    <div class="table__body" v-if="!loading">
      <div
        class="table__body__entry"
        v-for="(item, k) in data"
        :key="`table-entry-${k}`"
      >
        <div
          class="table__col"
          :class="{ fixed: col.fixed }"
          :style="`flex-basis:${col.fixed}px`"
          v-for="(col, k) in cols"
          :key="k"
        >
          <slot :name="col.value" :item="item" :col="col" />

          <span v-if="!$scopedSlots[col.value]">{{ item[col.value] }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Table',
  props: {
    data: Array,
    loading: Boolean,
    cols: Array,
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '../../styles/style.scss';

.table {
  width: 100%;
}
.table__header {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #25304333;
  color: $secondary;
  font-size: 15px;
}

.table__col {
  flex: 1 20%;
  padding: 0 20px;

  &.fixed {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100px;
  }
}

.table__body {
  &__entry {
    height: 150px;
    margin-top: 20px;
    background: #25304333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
  }
}

.table__col__img {
  width: 150px;
  height: 120px;
  padding: 5px 10px;
  background-color: #1c2028;

  .img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.table__col__name {
  padding: 20px;
  flex: 1;

  .name {
  }
  .type {
    margin-top: 10px;
    font-size: 12px;
    line-height: 15px;
    color: #7b8ba7;
    -webkit-line-clamp: 1;
  }
  .wear {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.6;
    -webkit-line-clamp: 1;
  }
}
</style>
