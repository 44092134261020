<template>
  <div class="store mt-5">
    <div class="container">
      <b-modal
        id="order-success"
        size="md"
        centered
        hide-header
        hide-footer
      >
        <div class="text-right">
          <button
            class="btn btn-link"
            style="font-size: 22px;"
            @click="$bvModal.hide('order-success')"
          >
            <fa :icon="['far', 'times-circle']" />
          </button>
        </div>
        <div
          v-if="currentItem"
          class="order"
        >
          <h5 class="text-white">
            商品结算
          </h5>

          <long-store-item :item="currentItem" />
          <div class="purchase">
            <button
              class="btn pt-3 pb-3 btn-block btn-primary"
              @click="purchase(currentItem)"
            >
              确认购买 ${{ currentItem.price }}
            </button>
          </div>
        </div>
      </b-modal>

      <div class="d-flex align-items-center mb-5">
        <div class="mx-3 text-white">
          在售物品
          <span>({{ items.length }})</span>
        </div>
        <button class="btn btn-outline-danger text-white">
          我要出售
        </button>
        <button class="btn btn-outline-info text-white ml-3">
          我要求购
        </button>
      </div>

      <div class="section-header">
        <div
          v-if="game"
          class="tabs pointer"
        >
          <tabs
            v-model="game"
            :options="types"
          />

          <search-input
            v-model="keyword"
            class="ml-auto"
            placeholder="搜索关键字"
          />
        </div>
      </div>

      <div class="d-flex justify-content-between flex-wrap my-4">
        <small
          v-if="filteredItems"
          class="found-label"
        >
          <span v-if="keyword">关键字: {{ keyword }},</span>
          发现{{ filteredItems.length }} 件饰品
        </small>
        <b-pagination
          v-model="currentPage"
          :total-rows="filteredItems.length"
          :per-page="perPage"
          first-number
          last-number
        />
      </div>

      <div class="inventories">
        <iTable
          :data="pagedItems"
          :loading="loading"
          :cols="cols"
        >
          <template v-slot:price="{ item, col }">
            <span
              :class="{
                'text-danger': col.label === '在售价格（起价）',
                'text-info': col.label === '求购价格（最高价）',
              }"
            >
              ¥ {{ item.price }}
            </span>
          </template>

          <template v-slot:name="{ item }">
            <div class="d-flex">
              <div class="table__col__img">
                <div
                  class="img"
                  :style="`background-image:url(${item.image_url})`"
                />
              </div>
              <div class="table__col__name">
                <div class="name clamp">
                  {{ nameFilter(item.name).excludeWear }}
                </div>
                <div class="type clamp">
                  {{ item.type }}
                </div>
                <div class="wear clamp">
                  {{ nameFilter(item.name).wear }}
                </div>
              </div>
            </div>
          </template>

          <template v-slot:operation="{ item }">
            <button
              class="btn text-secondary p-0"
              @click="$router.push(`/market/${item.app_id}`)"
            >
              查看
            </button>
          </template>
        </iTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AppService, MarketService } from '@/services/api'
import LongStoreItem from '@/views/Store/LongStoreItem'
import SearchInput from '@/components/SearchInput'
import iTable from './Table.vue'
import { nameFilter } from '@/utils'

const COLS = [
  { label: '名称', value: 'name' },
  { label: '在售数量', value: 'app_id', fixed: '100' },
  { label: '在售价格（起价）', value: 'price', fixed: '200' },
  { label: '求购数量', value: 'app_id', fixed: '100' },
  { label: '求购价格（最高价）', value: 'price', fixed: '200' },
  { label: '操作', value: 'operation', fixed: '100' },
]

const SORT_OPTIONS = [
  {
    icon: 'chevron-up',
    value: 'up',
    label: '大 - 小',
  },
  {
    icon: 'chevron-down',
    value: 'down',
    label: '小 - 大',
  },
]

export default {
  name: 'Store',
  components: {
    LongStoreItem,
    SearchInput,
    iTable,
  },
  data() {
    return {
      items: [],
      sort: 'up',
      sortOptions: SORT_OPTIONS,
      cols: COLS,
      loading: false,
      currentPage: 1,
      perPage: 24,
      keyword: '',
      rarity: '',
      currentItem: null,
      game: null,
      types: [
        {
          label: 'CSGO',
          value: 730,
          rarities: [
            '消费级',
            '军规级',
            '工业级',
            '匕首',
            '受限',
            '保密',
            '隐秘',
            '普通级',
            '高级',
            '非凡',
            '奇异',
            '卓越',
            '违禁',
          ],
        },
        {
          label: 'Dota2',
          value: 570,
          rarities: [
            '普通',
            '罕见',
            '稀有',
            '神话',
            '不朽',
            '传说',
            '至宝',
            '远古',
          ],
        },
      ],
    }
  },
  computed: {
    filteredItems() {
      const order = this.sort === 'up' ? -1 : 1
      return this.items
        .filter(item => item.app_id === this.game.id)
        .filter(item =>
          item.name.toLowerCase().includes(this.keyword.toLowerCase())
        )
        .filter(item => item.type.indexOf(this.rarity) >= 0)
        .sort((item1, item2) => (item1.price - item2.price) * order)
    },
    pagedItems() {
      const index = (this.currentPage - 1) * this.perPage
      const max = this.filteredItems.length
      return this.filteredItems.slice(
        index,
        Math.min(max, index + this.perPage)
      )
    },
    ...mapGetters(['user', 'app']),
  },
  watch: {
    currentPage() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  },
  mounted() {
    this.game = this.types[0]
    this.loadData()
  },
  methods: {
    nameFilter(val) {
      return nameFilter(val)
    },
    toast(message) {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        solid: true,
      })
    },
    async loadData() {
      this.loading = true
      try {
        const { data } = await MarketService.getAllItems()
        this.items = data
      } catch (e) {
      } finally {
        this.currentInventory = null
        this.loading = false
      }
    },
    buy(item) {
      this.currentItem = item
      this.$bvModal.show('order-success')
    },
    async purchase(item) {
      try {
        await AppService.buyItem(item.market_hash_name)
        this.$bvModal.hide('order-success')
        this.toast(this.currentItem.name + ' 购买成功')
        this.currentItem = null
      } catch (e) {
        this.toast('购买失败！请确认你的交易链接设置正确或有足够余额')
      } finally {
        this.$store.dispatch('LOAD_USER')
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';

.store {
  .banner {
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
    position: relative;
    background: url(../../assets/banner.svg);
    background-size: cover;

    h2,
    h3 {
      color: #fff;
      font-style: normal;
      font-weight: normal;
    }

    h2 {
      font-size: 62px;
      position: absolute;
      top: 40%;
      left: 10%;
    }

    h3 {
      font-size: 40px;
      line-height: 76px;
    }
  }

  .section-header {
    margin-bottom: 2em;
    display: flex;
    align-items: flex-end;

    .type {
      width: 186px;
      height: 66px;
      line-height: 66px;
      margin-right: 30px;
      color: #fff;
      font-size: 28px;
      position: relative;
      display: flex;
      justify-content: center;

      img {
        position: absolute;
        top: 0;
      }
    }

    .tabs {
      width: 100%;
      border: none;

      .tab {
        padding: 0 10px;
        position: relative;
        padding: 0 30px;
        color: #5c6c87;

        &.active {
          color: #fff;
        }

        &::after {
          content: '';
          width: 100%;
          height: 1px;
          background: #88888866;
          position: absolute;
          bottom: -3px;
          left: 0;
        }
      }
    }
  }
}

.found-label {
  color: #606c85;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
}

.no-item {
  width: 100%;
  height: 100px;
  color: #bbb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rarities {
  margin-bottom: 1em;
}

.purchase {
  width: 100%;
  margin: 3em 0;
  padding: 0 2em;
}

.icon {
  font-size: 0.8em;
  color: #6242da;
}

.store-warning {
  font-size: 12px;
  margin: 2em;
  padding: 10px;
  background: rgba(214, 214, 214, 0.2);
}

.order {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__desc {
    color: #6a6996;
    font-size: 12px;
  }
}

.inventories {
  display: flex;
  flex-wrap: wrap;
}

.keyword {
  margin-left: auto;
  width: 300px;
  background-color: transparent;
  border-color: #434365;
}

@media screen and (min-width: 1080px) {
  .store-item {
    flex: 1 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 760px) and (max-width: 1080px) {
  .store-item {
    flex: 1 33.33333%;
    max-width: 33.33333%;
  }
}

@media screen and (min-width: 520px) and (max-width: 760px) {
  .store-item {
    flex: 1 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 520px) {
  .store-item {
    flex: 1 100%;
    max-width: 100%;
  }
}

.table__col__img {
  width: 150px;
  height: 120px;
  padding: 5px 10px;
  background-color: #1c2028;

  .img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.table__col__name {
  padding: 20px;
  flex: 1;

  .type {
    margin-top: 10px;
    font-size: 12px;
    line-height: 15px;
    color: #7b8ba7;
    -webkit-line-clamp: 1;
  }
  .wear {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.6;
    -webkit-line-clamp: 1;
  }
}
</style>
